import React, {useState, useEffect, useMemo, lazy, Suspense, useContext} from 'react';
import { graphql } from 'gatsby';
import { globalHistory } from '@reach/router'
import {catMatch} from '../../../lib/functions/util-functions';
import Hero from '../../../modules/hero/hero';
import  {archive, archive__hero, archive__noResult, container__tiles, btnWrapper} from "./archive.module.scss";
import { LangContext } from '../../../context/LocaleContext';

const Button = lazy(() =>import('../../../components/button/button'));
const CategoryFilter = lazy(() =>import('./categoryFilter/categoryFilter'));
const ContentBlock = lazy(() =>import('../../../components/contentBlock/contentBlock'));
const HtmlBlock = lazy(() =>import('../../../components/htmlBlock/htmlBlock'));
const Tiles = lazy(() =>import('../../../modules/tiles/tiles'));
const RenderModules = lazy(() =>import("../../../lib/renders/renderModules"));

const Archive = ({data, location, pageContext}) => {
  const { getTranslation } = useContext(LangContext);
  const {datoCmsPageArchive, allDatoCmsCategoryNews, allDatoCmsCategoryCaseStudy, allDatoCmsPosttypeNews, allDatoCmsPosttypeCaseStudy } = data || {};
  const allCats = allDatoCmsCategoryNews?.nodes.length > 0 ? allDatoCmsCategoryNews.nodes : (allDatoCmsCategoryCaseStudy?.nodes || {});
  const allPosts = useMemo(()=>allDatoCmsPosttypeNews?.nodes.length > 0 ? allDatoCmsPosttypeNews.nodes : (allDatoCmsPosttypeCaseStudy?.nodes || {}),[allDatoCmsPosttypeNews, allDatoCmsPosttypeCaseStudy]);
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const param = 'cat';
  const displayInc = 12;
  const [numberToDisplay, setNumberToDisplay] = useState(displayInc);
  const [cats, setCats] = useState(params && params.get(param) ? params.get(param).split(',') : allCats.find((cat)=>cat.slug === pageContext.locale.toLowerCase())?.slug ? [allCats.find((cat)=>cat.slug === pageContext.locale.toLowerCase())?.slug] : []);
  const [allDisplayedPosts, setAllDisplayedPosts] = useState(catMatch(allPosts, 'categories', cats, 'OR', 'slug'));
  const {hero, title, newsLetterTile, queryNotFoundMessage} = datoCmsPageArchive || {};

  useEffect(() => {
    let sortedPosts = catMatch(allPosts, 'categories', cats, 'OR', 'slug');
    setAllDisplayedPosts(sortedPosts ? [...sortedPosts] : false);
    setNumberToDisplay(displayInc);
  }, [cats, allPosts, setNumberToDisplay, setAllDisplayedPosts, displayInc]);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH'){
        let newParams = new URLSearchParams(location.search);
        setCats(params && newParams.get(param) ? newParams.get(param).split(',') : []);
      } 
    })
  }, [setCats, params, param, location.search]);

  return (
  <div className={archive}>
    {
      hero && <Hero className={archive__hero} data={hero[0]} headingTag={'h2'} eyebrow={<HtmlBlock tag="h1">{title}</HtmlBlock>} />
    }
    <Suspense fallback="">
      <div className="container">

        <CategoryFilter data={allCats} title={getTranslation('Categories')} activeCats={cats} setCats={setCats} param={param}/>
      </div>
      <div className={container__tiles}>
        <div className="container">
          {
            ((allDisplayedPosts.length === 0) || !allDisplayedPosts) &&
            <ContentBlock data={queryNotFoundMessage[0]} className={archive__noResult} linkclass="btn--tertiary" />
          }
          {
          allDisplayedPosts && (allDisplayedPosts.length > 0) && <Tiles className="archive__tiles" numToDisplay={numberToDisplay} data={{'tiles': allDisplayedPosts}} insertIndex={4} insertData={{content: newsLetterTile}} />  
          }
          {
            numberToDisplay <= allDisplayedPosts.length &&
            <div className={btnWrapper}>
              <Button onClick={()=>setNumberToDisplay(numberToDisplay + displayInc)} className="btn--primary">{getTranslation('LoadMore')}</Button>
            </div>
          }
        </div>
      </div>
      {
        datoCmsPageArchive?.supportingModules && <RenderModules data={datoCmsPageArchive?.supportingModules}/>
      }
    </Suspense>
  </div>
  )
}

export default Archive


export const query = graphql`
  query($id: String, $locale : String!, $categoryType : String, $postType : String) {
    datoCmsPageArchive(originalId : {eq : $id}, locale: {eq: $locale}){
      hero{
        ...HeroFragment
      }
      newsLetterTile{
        ...HubspotContentFragment
      }
      title
      queryNotFoundMessage{
        ...HeadingCopyLinkFragment
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      supportingModules{
        __typename
        ...ModuleLogosFragment
        ...ModuleTextContentFragment
        ...ModuleCTASideBySideFragment
      }
    }

    allDatoCmsCategoryCaseStudy(sort: {fields: [position], order: DESC}, filter: { model: { apiKey: {eq: $categoryType }}}){
      nodes{
        locale
        slug
        title
        model{
          apiKey
        }
      }
    }

    allDatoCmsCategoryNews(sort: {fields: [position], order: ASC}, filter: { model: { apiKey: {eq: $categoryType  }}}){
      nodes{
        locale
        slug
        title
        model{
          apiKey
        }
      }
    }

    allDatoCmsPosttypeNews(sort: {fields: [meta___createdAt], order: DESC}, filter: { model: { apiKey: {eq: $postType  }}}){
      nodes{
        categories{
          slug
          title
        }
        id
        locale
        featuredImage{
          ...ThirdImageFragment
        }
        meta{
          createdAt(formatString: "MMMM D, YYYY")
        }
        model{
          apiKey
        }
        seo{
          description
        }
        slug
        title
      }
    }

    allDatoCmsPosttypeCaseStudy(sort: {fields: [position], order: ASC}, filter: { model: { apiKey: {eq: $postType  }}}){
      nodes{
        categories{
          slug
          title
        }
        id
        locale
        featuredImage{
          ...ThirdImageFragment
        }
        model{
          apiKey
        }
        seo{
          description
        }
        slug
        title
      }
    }

  }
`

